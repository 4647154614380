import React from 'react'
import GatsbyLink  from './link'

class NavMenuTemplate extends React.Component {

  render() { 

    const data = this.props.data;
    const section = this.props.section;
    const active = this.props.active;

    return (
      <div className="nav-menu-portlet">
        {!!section &&
          <h2 className="nav-menu-portlet__title">{section}</h2>
        }
        <ul className="nav-menu-portlet__list">
          {data.map((item, index) => {
            return <li key={index}  className={item.friendlyUrl===active?"nav-menu-portlet__item  nav-menu-portlet__item--active":"nav-menu-portlet__item "}>
                <GatsbyLink to={`/${item.friendlyUrl}`} title={item.titolo} className="nav-menu-portlet__link">{item.titolo}</GatsbyLink>
              </li>
          })}

        </ul>
      </div>
    )
  }

}

export default NavMenuTemplate;