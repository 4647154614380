import React from 'react'
import Helmet from 'react-helmet'
import Breadcrumbs from '../components/breadcrumbs'
import Hero from '../components/hero'
import DocumentiUtili from '../components/documenti-utili'
import Layout from "../components/layout"
import { graphql } from "gatsby"
import NavMenu from '../components/nav-menu'


class AreaStudentiInterna extends React.Component {
  
  render() { 
    
    const area = this.props.data.area;
    const pagina = this.props.data.pagina;
    const hero = this.props.data.hero;
    const docs = pagina.attachment?pagina.attachment:area.attachment;
    const breadcrumb = [
      {"title": area.titolo,"link": "/"+area.friendlyUrl},
      {"title": pagina.titolo}
    ];
    const pagineFiglie = [{titolo: "Descrizione", friendlyUrl: area.friendlyUrl}].concat(area.pagineFiglie);

    const datahero = {
      "title": "Centro Zootecnico Didattico Sperimentale",
      "subtitle": "Area studenti",
      "arealink": false 
    };

    return (
      <Layout location={this.props.location}>
        <Helmet
           title={`${pagina.titolo} ${area.titolo}`}
           bodyAttributes={
            { class :'has-hero'}
          }
        />
        <Hero data={datahero} cover={hero.childImageSharp}/>

        <div className="container">
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              {!!pagina && 
                <div dangerouslySetInnerHTML={{ __html: pagina.testo.childMarkdownRemark.html }} >
                
                </div>
              }
            </div>
            <div className="col-md-4">
              {
                <NavMenu section="Area Studenti" active={pagina.friendlyUrl} data={pagineFiglie}/>
              }
              {!!docs &&
                <DocumentiUtili section="Documenti Utili"  data={docs}/>
              }
            </div>
          </div>
        </div>

  

           

      </Layout>
    )
  }

}

export default AreaStudentiInterna;

export const pageQuery = graphql`query StudentiInternaDetailQuery($root_id: String!, $contentful_id: String!) {
  pagina: contentfulContenutoGenerico(contentful_id: {eq: $contentful_id}) {
    titolo
    friendlyUrl
    testo {
      childMarkdownRemark {
        html
      }
    }
    attachment {
      title
      file {
        url
      }
    }
  }
  area: contentfulContenutoGenerico(contentful_id: {eq: $root_id}) {
    titolo
    friendlyUrl
    attachment {
      title
      file {
        url
      }
    }
    pagineFiglie {
      contentful_id
      friendlyUrl
      titolo
    }
  }
  hero: file(relativePath: {eq: "img_area-studenti.png"}) {
    childImageSharp{
      fluid(maxWidth: 1920){
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}
`;
